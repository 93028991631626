import BansAndMutes from "pages/BansAndMutes";
import Debug from "pages/Debug";
import Marketplace from "pages/Marketplace";
import Profile from "pages/Profile";
import { Route, Routes } from "react-router-dom";
// import AccountReplenishment from "pages/AccountReplenishment";
import AccountReplenishment2 from "pages/AccountReplenishment2";
import AdminPages from "pages/AdminPages/AdminPages";
import Application from "pages/Application";
import Case from "pages/Cases/Case";
import CaseItem from "pages/Cases/CaseItem/CaseItem";
import { Contract } from "pages/Contract/Contract";
import Dashboard from "pages/Dashboard";
import PrivateConversation from "pages/Profile/Tabs/PrivateConversation";
import Rating from "pages/Rating";
import Store from "pages/Store";
import Contact from "pages/Store/Contact";
import FailMoney from "pages/Store/FailMoney";
import Offer from "pages/Store/Offer";
import Policy from "pages/Store/Policy";
import Rules from "pages/Store/Rules";
import SuccessMoney from "pages/Store/SuccessMoney/SuccessMoney";
import Support from "pages/Support";
import SupportAdmins from "pages/SupportAdmins";
import Ticket from "pages/Ticket";
import TradePage from "pages/Trade/Trade";
import Vacansy from "pages/Vacansy";
import VipShop from "pages/VipShop/VipShop";
import TradeRoom from "./Modal/Trade/TradeRoom/TradeRoom";
import BattlPass from "pages/BattlPass/BattlPass";
import Hints from "pages/Store/Hints/Hints";
import Clans from "pages/Clans/Clans";
import ClanId from "pages/Clans/ClanId/ClanId";
// const Marketplace = lazy(smartLazy(() => import('pages/Marketplace'), 500))
// const Dashboard = lazy(smartLazy(() => import('pages/Dashboard'), 700))
// const Debug = lazy(() => import('pages/Debug'))
// const BansAndMutes = lazy(() => import('pages/BansAndMutes'))
// const AccountReplenishment = lazy(() => import('pages/AccountReplenishment'))
// const Rating = lazy(() => import('pages/Rating'))
// const Vacansy = lazy(() => import('pages/Vacansy'))
// const Support = lazy(() => import('pages/Support'))
// const PrivateConversation = lazy(() => import('pages/Profile/Tabs/PrivateConversation'))
// const Profile = lazy(() => import('pages/Profile'))
// const Store = lazy(() => import('pages/Store'))

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/marketplace" element={<Marketplace />} />
      <Route path="/chest" element={<Case />} />
      <Route path="/chest/:id" element={<CaseItem />} />
      <Route path="/debug" element={<Debug />} />
      <Route path="/bans" element={<BansAndMutes />} />
      <Route path="/purse" element={<AccountReplenishment2 />} />
      <Route path="/success" element={<SuccessMoney />} />
      <Route path="/fail" element={<FailMoney />} />
      <Route path="/rating" element={<Rating />} />
      <Route path="/vacansy" element={<Vacansy />} />
      <Route path="/support" element={<Support />} />
      <Route path="/vip" element={<VipShop />} />
      <Route path="/chat/:steamid64" element={<PrivateConversation />} />
      <Route path="/tickets/:id" element={<Ticket />} />
      <Route path="/applications/:id" element={<Application />} />
      <Route path="/rules/offer" element={<Offer />} />
      <Route path="/rules" element={<Rules />} />
      <Route path="/rules/Contact" element={<Contact />} />
      <Route path="/rules/Hints" element={<Hints />} />
      <Route path="/rules/PrivatePolicy" element={<Policy />} />
      <Route path="/admins" element={<AdminPages />} />
      <Route path="/contract" element={<Contract />} />
      <Route path="/supportAdmin" element={<SupportAdmins />} />
      <Route path="/battlepass" element={<BattlPass />} />

      <Route path="/clans">
        <Route path=":id" element={<ClanId />} />
        <Route path="" element={<Clans />} />
      </Route>

      {/* <Route path="/roulette" element={<Roulette />} /> */}
      <Route path="/TradeRoom" element={<TradeRoom />} />
      <Route path="/profile/:steamid64">
        <Route path=":tab" element={<Profile />} />
        <Route path="" element={<Profile />} />
      </Route>
      <Route path="/trade/">
        <Route path="" element={<TradePage />} />
      </Route>
      <Route path="/store">
        <Route path=":tab" element={<Store />} />
        <Route path="" element={<Store />} />
      </Route>
    </Routes>
  );
}
