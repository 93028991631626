import { ClansServise } from "API/ClansServise";
import ModalWrapper from "components/Templates/ModalWrapper";
import Button from "components/UI/Button";
import { useNavigate } from "react-router-dom";
import { Container } from "./styled";
interface Props {
  isActive: boolean;
  setActive: React.SetStateAction<any>;
}
export default function ExitClan({ isActive, setActive }: Props) {
  const navigate = useNavigate();
  return (
    <ModalWrapper
      headContent={"Подтверждение"}
      isActive={isActive}
      setActive={setActive}
      display={"flex"}
      params={{ height: "max-content" }}
    >
      <Container>
        <span style={{ fontSize: "16px" }}>
          Вы действительно хотите покинуть клан?
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "2rem",
            width: "100%",
          }}
        >
          <Button
            color="shadow"
            variant="default"
            height="5rem"
            params={{ width: "100%" }}
            onClick={async () => {
              const result = await ClansServise.leaveClan();
              if (result == true) {
                navigate("/clans");
                window.location.reload();
              }
            }}
          >
            Покинуть
          </Button>
          <Button
            color="blue"
            variant="default"
            height="5rem"
            params={{ width: "100%" }}
            onClick={() => setActive(false)}
          >
            Отмена
          </Button>
        </div>
      </Container>
    </ModalWrapper>
  );
}
