import { Container as ColumnContainer } from "./../Column/Styles";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

interface ContainerProps {
  isChatActive?: boolean;
}

export const StyledLayout = styled.main`
  /* background: ${(props) => props.theme.gradients.bg}; */
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-left: calc(${(props) => props.theme.consts.navBarWidth} + 4rem);
  /* padding-bottom: 5rem; */
  /* margin-bottom: 5rem; */
  @media (max-width: 1250px) {
    margin-left: 0.2rem;
  }
`;

export const Wrapper = styled.div`
  /* background: red; */
  background: ${(props) => props.theme.gradients.bg};
`;

export const Container = styled.div<ContainerProps>`
  transition: 0.5s;
  width: 100%;
  margin-bottom: 3rem;
  height: fit-content;
  position: relative;
  flex: 1;
  display: grid;
  grid-template-columns: 2.4rem 0.95fr;
  gap: 2rem;
  /* padding-right: 10.6rem; */
  ${ColumnContainer} {
    align-self: flex-start;
  }
  svg.active {
    path {
      fill-opacity: 1;
    }
  }
  @media (max-width: 1250px) {
    grid-template-columns: 1fr;
    padding-right: 0.8rem;
    flex: none;
  }
`;

export const ChatWrapper = styled.div<ContainerProps>`
  display: flex;
  position: fixed;
  gap: ${(props) => (props.isChatActive ? "1.6rem" : "0rem")};
  right: 3rem;
  transition: 0.5s;
  top: -0rem;
  z-index: 1000;
  /* width: 10rem; */
`;
export const MediumSpan = styled.span`
  ${(props) => props.theme.fonts.GothamMedium16}
  color: white;
  align-items: center;
  /* align-self: center; */
`;

export const MediumSpanLink = styled(NavLink)`
  ${(props) => props.theme.fonts.GothamMedium16}
  color: white;
  align-items: center;
  /* align-self: center; */
`;

export const LeftItemFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
`;

export const BlockItemIcon = styled.div`
  cursor: pointer;
  opacity: 30%;
  :hover {
    opacity: 70%;
  }
`;

export const ItemIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  /* margin-bottom: 5rem; */
`;

export const RightitemFooter = styled.div`
  /* padding: 0 5rem; */
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ChatSpan = styled.span`
  color: ${(props) => props.theme.colors.white.white100};
  ${(props) => props.theme.fonts.GothamBold14}
`;
export const FooterMain = styled.div`
  padding: 1.5rem 10rem 4rem 10rem;
  border-top: 0.15rem solid ${(props) => props.theme.stroke.stroke5};
  /* background-color: ${(props) => props.theme.colors.white.white20}; */
  margin-left: -3.99rem;
  margin-top: 5rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    text-align: center;
  }
`;
export const AsideITEMBlock = styled.div`
  display: flex;
  width: 35rem;
  flex-wrap: wrap;
  gap: 3rem;
  justify-content: space-between;
  /* padding: 0 5rem; */
  @media (max-width: 400px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
`;

export const ToggleChat = styled.div`
  z-index: 1000;
  ${(props) => props.theme.mixins.dfcenter};
  background-color: ${(props) => props.theme.colors.white.white20};
  border-radius: 2rem 0 0 2rem;
  flex-direction: column;
  gap: 0.4rem;
  cursor: pointer;
  width: 5rem;
  height: 7rem;
  bottom: 40rem;
  right: 0rem;
  padding: 1rem;
  border: 0.3rem solid ${(props) => props.theme.stroke.stroke5};
  /* ${(props) => props.theme.colors.white.white20}; */
  &:last-child {
    position: fixed;
    /* right: 0; */
  }
  button.dots {
    width: 60px;
    height: 30px;
    border: none;
    background: transparent;
    position: relative;
    cursor: pointer;
    &:focus {
      outline: none;
    }

    &.dots {
      &:after,
      &:before,
      span {
        width: 10%;
        height: 20%;
        border-radius: 100px;
        position: absolute;
        left: 0px;
        background: ${(props) => props.theme.colors.white.white100};
        transform: rotate(0deg);
        transition: all 0.5s;
        left: 1%;
        right: 1%;
        margin: 0 auto;
      }

      &:after,
      &:before {
        content: "";
      }

      &:after {
        top: -10%;
        margin-top: 0px;
      }
      &:before {
        bottom: -10%;
        margin-bottom: 0px;
      }

      span {
        top: 50%;
        margin-top: -3px;
      }

      &.on {
        span {
          opacity: 0;
        }

        &:after {
          transform: rotate(135deg) translate(11px, -11px);
          width: 30%;
          height: 7%;
        }

        &:before {
          transform: rotate(225deg);
          bottom: 50%;
          width: 31%;
          height: 7%;
        }

        span {
          transform: rotate(135deg);
        }
      }
    }
  }
  @media (max-width: 1250px) {
    display: none;
  }
`;

export const WrapSmallWidth = styled.div<{ active?: boolean }>`
  position: fixed;
  width: 100%;
  height: max-content;
  /* background: purple; */
  bottom: ${(props) => (props.active ? "0" : "-38rem")};
  /* left: 20%; */
  display: none;
  justify-content: center;
  flex-direction: column;
  transition: 0.5s;
  z-index: 10000;
  text-align-last: center;
  div {
    svg {
      width: 3rem;
      height: 3rem;
      background: blue;
      border-radius: 50%;
      padding: 1rem;
      align-self: center;
      cursor: pointer;
      margin-bottom: 1rem;
      transform: rotate(${(props) => (props.active ? "-180deg" : "0")});
      transition: 0.3s;
    }
  }
  @media (max-width: 1250px) {
    display: flex;
  }
`;

export const Item = styled(NavLink)`
  cursor: pointer;
  width: 100%;
  height: fit-content;
  text-align: center;
  padding: 1.1rem 0;
  transition: all 0.15s;
  flex-direction: column;
  ${(props) => props.theme.fonts.GothamMenuMedium14}
  ${(props) => props.theme.mixins.dfcenter}
	color: ${(props) => props.theme.colors.white.white70};
  gap: 0.7rem;
  position: relative;
  &.active {
    color: ${(props) => props.theme.colors.white.white100};
    background: ${(props) => props.theme.stroke.stroke5};
    &::before {
      content: " ";
      top: 0;
      left: 0;
      border-radius: 0 2rem 2rem 0;
      position: absolute;
      width: 0.7rem;
      height: 100%;
      background: ${(props) => props.theme.colors.blue.accent};
    }
    svg > * {
      transition: all 0.15s;
      fill-opacity: 1 !important;
    }
  }
`;
// export const Wrapper = styled.div``;
