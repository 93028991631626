import { endpoints, url } from "API";
import axios from "axios";
import {
  ClanMemberAction,
  ClanRolesEnum,
  ClanShopEnum,
} from "interfaces/Clans";

export class ClansServise {
  static async getClans() {
    try {
      const response = await axios.get(url + endpoints.clans.clans, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }
  static async getClan(id: string) {
    try {
      const response = await axios.get(
        url + endpoints.clans.clans + endpoints.clans.clan + `/${id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Authorization"),
          },
        }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async getTaskClan() {
    try {
      const response = await axios.get(
        url + endpoints.clans.clans + endpoints.clans.me,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async CreateClan(name: string, avatar: string) {
    try {
      const response = await axios.post(
        url + endpoints.clans.clans + endpoints.clans.createClan,
        {
          name: name,
          avatar: avatar,
        },
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async leaveClan() {
    try {
      const response = await axios.get(url + endpoints.clans.clans + "/leave", {
        withCredentials: true,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("Authorization"),
        },
      });
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }
  static async ChangeStatus(id: number) {
    try {
      const response = await axios.get(
        url +
          endpoints.clans.clans +
          endpoints.clans.updateStatusClan +
          `/${id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Authorization"),
          },
        }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async RequestToClan(id: string) {
    try {
      const response = await axios.get(
        url + endpoints.clans.clans + endpoints.clans.requestClan + `/${id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Authorization"),
          },
        }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async AcceptAndCancelClan(
    userId: number,
    status: number,
    DeleteAll?: boolean
  ) {
    try {
      const response = await axios.get(
        url +
          endpoints.clans.clans +
          endpoints.clans.accept +
          `?user_id=${userId}&status=${status}${
            DeleteAll == true ? "&type=all" : ""
          }`,
        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Authorization"),
          },
        }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async BuyImMarket(
    id: number,
    value: string | number,
    type: ClanShopEnum
  ) {
    const params: any = { id: id };

    // В зависимости от значения type добавьте нужное поле
    switch (type) {
      case ClanShopEnum.Avatar:
        params.avatar = value;
        break;
      case ClanShopEnum.ClanTag:
        params.clanTag = value;
        break;

      case ClanShopEnum.Name:
        params.name = value;
        break;
    }

    try {
      const response = await axios.post(
        url +
          endpoints.clans.clans +
          endpoints.clans.shop +
          endpoints.clans.buy,
        params,
        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Authorization"),
          },
        }
      );
      return response.data;
    } catch (error: any) {
      return error.response.data.message;
    }
  }
  static async GetChop() {
    try {
      const response = await axios.get(
        url + endpoints.clans.clans + endpoints.clans.shop,
        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Authorization"),
          },
        }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }
  static async UpdateMembers(
    action: ClanMemberAction,
    user_id: number,
    role?: ClanRolesEnum
  ) {
    try {
      const response = await axios.post(
        url + endpoints.clans.clans + endpoints.clans.update,
        {
          action: action,
          user_id: user_id,
          role: role,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Authorization"),
          },
        }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }
}
