import { ModalParams } from "interfaces/Modal";
import styled from "styled-components";

export const Container = styled.div<{ params?: ModalParams }>`
  width: ${(props) => (props.params?.width ? props.params.width : "60rem")};
  height: ${(props) => (props.params?.height ? props.params.height : "80rem")};
  border-radius: 2.5rem;
  ${(props) => props.theme.shadows.defaultShadow};
  border: 0.3rem solid ${(props) => props.theme.stroke.stroke5};
  background: ${(props) => props.theme.gradients.bg};
  padding: 3rem;
  display: flex;
  flex-direction: column;
  /* gap: 3rem; */
  svg {
    cursor: pointer;
    transition: 0.3s all;
    &:hover {
      opacity: 0.5;
    }
  }
  @media (max-width: 1250px) {
    padding: 1rem;
    width: 36rem;
  }
`;
interface DisplayProps {
  display: "flex" | "column" | "none";
}

export const Head = styled.div<DisplayProps>`
  display: ${(props) => props.display};
  align-items: center;
  justify-content: space-between;
  ${(props) => props.theme.fonts.GothamBold24};
  color: ${(props) => props.theme.colors.white.white100};
  @media (max-width: 500px) {
    width: 33rem;
    justify-content: space-between;
    /* text-align: center; */
    text-wrap: wrap;
  }
  text-align: center;
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  ${(props) => props.theme.fonts.GothamRegular20};
  color: ${(props) => props.theme.colors.white.white100};
`;
