import Layout from "components/Layout";
import SubHeader from "components/SubHeader";
import path from "path";
import { redirect, useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  ImageSuccess,
  Title,
  Wrapper,
  Text,
  ImageBack,
  WrapTitle,
  HeaderWrap,
  Url,
  Summary,
  P,
} from "./Styles";
import Button from "components/UI/Button";

export default function Rules() {
  const navigate = useNavigate();
  return (
    <Layout
      leftElement={<div />}
      headerElement={
        <HeaderWrap>
          {/* <UIKIT.ArrowBackMoney /> */}
          {/* <SubHeader>Договор – оферта</SubHeader> */}
          <Button variant="picker" height="5rem" className="active">
            Правила
          </Button>
          <Button
            variant="picker"
            height="5rem"
            onClick={() => navigate("/rules/offer")}
          >
            Оферта
          </Button>
          <Button
            variant="picker"
            height="5rem"
            onClick={() => navigate("/rules/PrivatePolicy")}
          >
            Политика конфидициальности
          </Button>
          <Button
            variant="picker"
            height="5rem"
            onClick={() => navigate("/rules/contact")}
          >
            Контакты
          </Button>
          <Button
            variant="picker"
            height="5rem"
            onClick={() => navigate("/rules/Hints")}
          >
            Подсказки
          </Button>
        </HeaderWrap>
      }
    >
      <Wrapper>
        <WrapTitle decor>
          Друзья, уважайте друг друга. Оскорбления в адрес игроков или сервера
          недопустимы и приведут к наказаниям.
        </WrapTitle>
        <WrapTitle>
          Подключаясь к любому серверу или посещая любой сервис проекта
          Freez-Blood, Вы автоматически соглашаетесь со всеми правилами и
          обязуетесь их соблюдать.
        </WrapTitle>
        <Text>Уважаемые игроки! Важные положения, которые следует учесть:</Text>
        <Text>
          1. Наши правила созданы с целью поддержания и обеспечению комфортной
          игры на серверах. Мы уважаем вашу индивидуальность и стремимся
          поддерживать дружелюбную атмосферу на проекте.
        </Text>
        <Text>
          2. Если у вас возникли вопросы или неясности относительно правил, не
          стесняйтесь обращаться в нашу{" "}
          <Url href="https://freez-blood.com/support">службу поддержки</Url> или
          в нашу&nbsp;
          <Url href="https://vk.me/freezblood" color="blue">
            группу ВКонтакте.
          </Url>
          Мы всегда готовы помочь и ответить на ваши вопросы.
        </Text>
        <Text>
          3. Нажав на раздел 'пояснение' под определенными правила, Вы сможете
          прочитать более подробное объяснение этих правил, чтобы лучше понять
          их смысл.
        </Text>
        <Text>
          Мы призываем каждого игрока соблюдать установленные правила и
          осуществлять свою игру в рамках этих правил. Все они направлены на то,
          чтобы каждый участник мог наслаждаться игрой и чувствовать себя
          комфортно в нашем игровом сообществе.
        </Text>
        <Title>I. Основные положения:</Title>
        <Text>
          1.1 Незнание правил не освобождает Вас от ответственности за их
          нарушение.
        </Text>
        <Text>
          1.2 Вы несете полную ответственность за любые действия, совершенные на
          проекте с использованием вашего аккаунта и компьютера, даже если вы не
          присутствовали в момент нарушений.
        </Text>
        <Text>
          1.3 Главная администрация имеет право в любое время изменять, удалять
          или добавлять новые правила по своему усмотрению без предварительного
          уведомления игроков проекта.
        </Text>
        <Text>
          1.4 Старшая администрация имеет право заблокировать доступ к серверам
          проекта или выдать мут по своему усмотрению, а также повысить срок
          мута или бана в соответствии с характером нарушений или
          обстоятельствами дела.
        </Text>
        <Text>
          1.5 Запрещено нарушать стабильную работу любых сервисов проекта. При
          обнаружении таких действий Ваш доступ к нашим серверам будет
          заблокирован.
        </Text>
        <Text>
          1.6 Администрация проекта не несет ответственности за потерю ваших
          реальных денег, скинов, или других предметов, а также за любые другие
          последствия. Вы несете полную ответственность за свои действия и их
          последствия.
        </Text>
        <Text>
          1.7 Все игроки равны между собой и обязаны соблюдать правила,
          независимо от пола, статуса (ютубера, стримера, VIP-игрока,
          администратора) или других обстоятельств.
        </Text>
        <Text>
          1.8 Если Вы заметили, что администратор злоупотребляет своими правами
          (нарушает правила, оскорбляет, унижает, травит, продает привилегии,
          шантажирует, угрожает и т.д.), вы можете подать{" "}
          <Url href="https://discord.com/channels/920259019912912906/1242099247063502931">
            жалобу
          </Url>{" "}
          , предоставив записи демонстраций, скриншоты, логи и другие
          доказательства. Однако, если Вы попытаетесь недобросовестно оклеветать
          администратора, Ваш доступ к серверам может быть заблокирован.
        </Text>
        <Text>
          1.9 В случае неудовлетворения оказанной услуги возможен возврат
          средств на баланс сайта при соблюдении условий: срок купленной услуги
          должен составлять не менее 30 дней, а с момента оплаты прошло не более
          14 дней. Более подробная информация находится в пункте 6{" "}
          <Url href="https://freez-blood.com/rules/offer">
            Оферты проекта Freezing Blood.
          </Url>
        </Text>
        <Text>
          1.10 Администрация вправе заблокировать ваш доступ к серверам, если
          обнаружит, что Вы являетесь членом старшего состава других проектов по
          CS2.
        </Text>
        <Text>
          1.11 Запрещено использовать личные данные других людей без их согласия
          в корыстных целях, включая настоящие ФИО, фотографии и прочую
          информацию. При обнаружении таких действий ваш доступ к серверам будет
          заблокирован.
          <details>
            <Summary>Пояснение</Summary>
            <P>
              Не считается нарушением правил использование публичной информации.
              Нарушением считается только использование личной информации без
              согласия владельца.
            </P>
          </details>
        </Text>

        <Text>
          1.12 Администрации разрешено выдавать перманентный мут в два чата:
          текстовом и голосовом, если у пользователя было 10 и более мутов за
          нарушение правил общения в течение последнего месяца.
          <details>
            <Summary>Пояснение</Summary>
            <P>
              Правила, которые действуют под данное правило: с 2.3 по 2.5 и с
              2.9 по 2.14.
              <br />
              Покупка размута не влияет на общее количество полученных
              нарушений, а лишь сокращает время вашего ограничения.
            </P>
          </details>
        </Text>
        <Text>
          1.13 Все действия администратора должны осуществляться через панель
          администратора и отображаться в текстовом чате игры. Обычным игрокам
          категорически запрещено проводить проверки других игроков. При
          обнаружении таких действий Ваш доступ к серверам будет заблокирован.
        </Text>

        <Title>II. Общение:</Title>
        <Text>
          2.1 В голосовом чате серверов запрещается предлагать, а также
          обсуждать процесс покупки, игры и обмена серверных скинов.
          <br /> ➤ Мут голосового чата на час ➤ день ➤ неделю
          <details>
            <Summary>Пояснение</Summary>
            <P>
              Примеры нарушающие данное правило: "давай обмен", "купите скин",
              "добавь М9 Рубин в обмен" и т.д.
              <br />
              Примеры НЕ нарушающие данное правило: "сколько стоит что-либо",
              "розыгрыш чего-либо", "обсуждение прошедших обменов, игр, продаж"
              и т.д.
            </P>
          </details>
        </Text>
        <Text>
          2.2 Запрещается использовать посторонние звуки, шумы, крики, музыку,
          программы для изменения голоса и спамить. (повторять сообщения без
          остановки, использовать массовые сообщения с помощью биндов)
          <br /> ➤ Мут голосового или текстового чата на час ➤ день ➤ неделю
        </Text>
        <Text>
          {" "}
          2.3 На проекте строго запрещается любое прямое или косвенное
          оскорбление или принижение других игроков. В рамках игрового процесса
          допускается использование матерных и бранных выражений, но только при
          условии, что они не направлены на кого-либо лично и не вызывают
          неприемлемого или неадекватного поведения, включая агрессивные
          действия в отношении других участников проекта.
          <br /> ➤ Мут голосового или текстового чата на час ➤ неделю
          <details>
            <Summary>Пояснение</Summary>
            <P>
              Оскорбления в шуточной форме также допускаются, при условии, что
              они не вызывают негативных эмоциональных реакций или обид у других
              игроков и не приводят к неадекватному поведению или агрессии.
              Однако, игроки должны помнить о мере и убедиться, что их шутки не
              переходят границы приемлемого поведения и не создают неприятных
              ситуаций для других участников проекта.
            </P>
          </details>
        </Text>
        <Text>
          {" "}
          2.3.1 Оскорбления пола, расы, национальности, языка, происхождения,
          отношения к религии.
          <br /> ➤ Мут голосового или текстового чата на день ➤ неделю
          <details>
            <Summary>Пояснение</Summary>
            <P>
              <br />
              Примеры нарушающие данное правило:
              <br /> • Оскорбление пола: "Ты всего лишь женщина, не можешь
              понять сложные вещи." и т.д.
              <br /> • Оскорбление расы: "Ты чернокожий, не имеешь права на
              такие возможности, как белые люди." и т.д.
              <br /> • Оскорбление национальности: "Ты русский? Тогда должен
              быть алкоголиком и насильником." и т.д.
              <br /> • Оскорбление языка или происхождения: "Ты говоришь с
              акцентом, тебя невозможно понять, возвращайся в свою страну." и
              т.д.
              <br /> • Оскорбление отношения к религии: "Ты веришь в такую
              глупую религию? Ты наивный и глупый." и т.д.
            </P>
          </details>
        </Text>
        <Text>
          2.4 Запрещено критиковать действия администрации.
          <br /> ➤ Мут голосового или текстового чата на час ➤ день ➤ неделю
          <details>
            <Summary>Пояснение</Summary>
            <P>
              Критика действий администрации - умышленные негативные замечания,
              обвинения, недовольства, возражения и претензии после выдачи
              какого либо наказания администратором.
              <br />
              Примеры нарушающие данное правило: "это не нарушение", "ты мне
              просто так дал мут", и т.д. <br />
              Примеры НЕ нарушающие данное правило: спокойное обсуждение с
              администратором (при его согласии) причины получения наказания.
              Если администратор отказывается разъяснить ситуацию, просьба
              обратиться к кураторам проекта или подать жалобу на форуме.
            </P>
          </details>
        </Text>
        <Text>
          2.5 Запрещено выдавать себя за администратора, если вы им не
          являетесь. <br />
          P.S. Если будет обнаружено, что Вы пытались вызвать игрока на
          проверку, Ваш доступ к серверам будет навсегда заблокирован.
          <br /> ➤ Мут голосового или текстового чата на час ➤ день ➤ неделю
        </Text>
        <Text>
          2.6 Запрещается рекламировать:
          <br /> ● Собственные каналы, группы, беседы и стримы.
          <br /> ➤ Мут голосового или текстового чата на день ➤ неделю ➤ Бан на
          месяц
          <br /> ● Другие игровые проекты и сервера CS2.
          <br /> ➤ Бан на неделю ➤ месяц
          <br /> P.S. Разрешено обсуждение сайтов азартных игр, которые не
          рекламируют и не имеют игровые сервера CS2.
        </Text>
        <Text>
          2.7 Запрещено мониторить:
          <br /> ● На сервере.
          <br /> ➤ Мут голосового или текстового чата на час ➤ день <br /> ● На
          иных платформах общения.
          <br /> ➤ Бан на неделю ➤ месяц
          <details>
            <Summary>Пояснение</Summary>
            <P>
              Мониторинг — это, когда ты, твои союзники или наблюдатели сообщают
              точное местоположение игроков или предоставляют информацию,
              которую увидели или услышали на повторе после смерти либо при
              наблюдении за игрой.
            </P>
          </details>
        </Text>
        <Text>
          2.8 Запрещено общение на любом языке, кроме русского.
          <br /> Исключение: диалог происходит между 4 и более носителями языка,
          а также сообщения написанные транслитом ("Privet kak dela?", "Horosho
          tvoi kak?")
          <br /> ➤ Мут голосового или текстового чата на час ➤ день
        </Text>

        <Text>
          2.9 Запрещено разводить межнациональные и политические споры и
          конфликты.
          <br /> ➤ Мут голосового или текстового чата на день ➤ неделю ➤ месяц
        </Text>
        <Text>
          2.10 При выявлении обмана администрации.
          <br /> ➤ Мут голосового или текстового чата на день ➤ Бан на месяц
          <details>
            <Summary>Пояснение</Summary>
            <P>
              Обманом администрации является: клевета невинного игрока,
              фальсификация доказательств нарушения. <br /> Использовать
              стратегии, тактики и игровые механики для достижения преимущества
              над противниками, при условии, что это не является нарушением
              правил проекта, не является обманом администрации. <br /> Примеры
              НЕ нарушающие данное правило: "ласт 1 хп", " я на б" и т.д.
            </P>
          </details>
        </Text>
        <Text>
          2.11 Запрещено прямое или косвенное оскорбление любых сервисов
          проекта. <br /> ➤ Мут голосового или текстового чата на неделю ➤ Бан
          на месяц
        </Text>
        <Text>
          2.12 Запрещено любое косвенное оскорбление или принижение родных.
          (примечание: "mq" расценивается, как оскорбление родных)
          <br /> ➤ Мут голосового и текстового чата на месяц ➤ 3 месяца ➤ Бан
          навсегда
        </Text>
        <Text>
          2.13 Запрещено любое пропагандирование и обсуждение запрещенных в РФ
          политических акций, объединений, наркотических веществ и подобных
          неадекватных движений.
          <br /> Мут голосового или текстового чата на неделю ➤ Бан на неделю ➤
          Бан на месяц
        </Text>
        <Text>
          2.14 Запрещено предлагать, покупать, продавать что-либо за реальные
          деньги. <br /> P.S. Розыгрыши и раздачи в любом денежном эквиваленте
          разрешены.
          <br /> ➤ Бан навсегда
        </Text>

        <Title>III. Игровой Процесс</Title>
        <Text>
          3.1. На проекте запрещено использование, хранение или распространение
          любых видов читов, скриптов, запрещенных конфигурационных файлов, а
          также сторонних программ. Запрещено также иметь подписки на читы.{" "}
          <br />
          Бан выдается в случае обнаружения читов, скриптов или при любых
          изменениях в запрещенных конфигурационных файлах в течение 30-ти дней.
          Также считается нарушением распространение любых ссылок и файлов,
          связанных с запрещенным программным обеспечением.
          <br /> ➤ Бан навсегда
        </Text>
        <Text>
          3.1.1 При отказе от проверки на наличие стороннего программного
          обеспечения.
          <br /> ➤ Бан на 3 месяца
          <details>
            <Summary>Пояснение</Summary>
            <P>
              Примеры нарушающие данное правило:
              <br /> • Отказ проверяемого в выполнение каких либо просьб
              администратора
              <br /> • Выход с сервера
              <br /> • Предоставление ложного дискорда
              <br /> • Неадекватное поведение
              <br /> • Троллинг и т.д.
            </P>
          </details>
        </Text>
        <Text>
          3.2 Запрещен созыв в любую стороннюю игру, проекты, кроме сервисов
          проекта Freez Blood.
          <br /> ➤ Бан на час ➤ неделю
          <details>
            <Summary>Пояснение</Summary>
            <P>
              Просьба игрока зайти в определенный мессенджер, такой как Discord
              или VK, не является нарушением.
            </P>
          </details>
        </Text>
        <Text>
          3.3 Запрещено мешать игровому процессу другим игрокам.
          <br /> ➤ Бан на час ➤ неделю
          <details>
            <Summary>Пояснение</Summary>
            <P>
              Примеры нарушающие данное правило во время игрового времени:
              <br /> • стрелять в союзников
              <br /> • мешать стрелять по противникам
              <br /> • флешить своих и т.д.
              <br /> • запрещено умышленное препятствование передвижению или
              взаимодействию союзников
              <br /> Разминка, начало и конец раунда не входят в игровое время.
            </P>
          </details>
        </Text>
        <Text>
          3.4 Запрещено препятствовать администрации в их работе.
          <br /> ➤ Бан на час ➤ неделю
          <details>
            <Summary>Пояснение</Summary>
            <P>
              Препятствие администрации представляет собой любые действия,
              направленные на вмешательство или затруднение работы
              администрации. <br />
              Сообщения в админ-чате должны быть информативными и
              соответствовать теме администрирования. Любое обсуждение, не
              связанное с работой администрации, нарушает данный пункт правил.
              <br />
              Примеры нарушающие данное правило:
              <br /> • обсуждение игровых аспектов, не связанных с
              администрированием в админ чате
              <br /> • отправка мемов или шуток в админ чат
              <br /> • спам в админ чат
              <br /> • выдача нахождения администратора в режиме невидимки
              <br /> • плагиат чужого ника (Исключение: если никнеймы хотя бы
              отличаются 1 символом в нике визуально)
              <br /> • самопризнание в использовании запрещенных
              читов/скриптов/кфг взаимодействию союзников
              <br /> • спам необоснованными репортами/жалобами <br /> •
              использование биндов и скриптов на развороты более чем 90 градусов
            </P>
          </details>
        </Text>
        <Text>
          3.5 Запрещаются договоренности между игроками, которые приводят к
          оскорбительным или провокационным ситуациям, а так же необоснованным
          жалобам и т.д.
          <br /> ➤ Бан на неделю ➤ месяц
          <details>
            <Summary>Пояснение</Summary>
            <P>
              Под "договоренностями между игроками" понимаются сговоры,
              сгруппированные действия или любые другие формы соглашений,
              направленные на оскорбление или провокацию других игроков, а так
              же необоснованные жалобы с целью нанести им ущерб или наказание,
              не учитывая фактические нарушения правил.
            </P>
          </details>
        </Text>
        <Text>
          3.6 Запрещено использование багов сервера и недоработок.
          <br /> ➤ Бан на час ➤ неделю
          <details>
            <Summary>Пояснение</Summary>
            <P>
              Запрещено использование любых багов или ошибок на картах в CS2,
              если эти баги не могут быть использованы на официальных картах,
              предоставленных разработчиками игры, а так же недоработки плагинов
              и сервисов проекта.
            </P>
          </details>
        </Text>
        <Text>
          3.7 В режиме дуэли запрещается поворачиваться более чем на 90 градусов
          влево или вправо, приближаться слишком близко, а так же наклонять
          голову вниз или вверх.
          <br /> ➤ Бан на час ➤ неделю
        </Text>
        <Text>
          3.8 Запрещено использовать никнеймы и изображение профиля в которых
          содержатся:
          <br /> ● Матерные оскорбления.
          <br /> ➤ Кик ➤ Бан на час ➤ день
          <br /> ● Любого рода дискриминация по различным признакам.
          <br /> ● Реклама серверов CS.
          <br /> ● Названия эротического или порнографического содержания,
          которые являются неприемлемыми и нарушают правила этикета и приличия.
          <br /> ● Запрещенные в РФ политические акции, объединения,
          наркотические вещества и подобные неадекватные движения.
          <br /> ● Выдача себя за администратора.
          <br /> ● Любое оскорбление или упоминание родных.
          <br /> ➤ Кик ➤ Бан на день ➤ неделю
          <br /> ● Ники главной и старшей администрации.
          <br /> ● Прямое или косвенное оскорбление любых сервисов проекта.
          <br /> ➤ Бан на неделю ➤ месяц
          <details>
            <Summary>Пояснение</Summary>
            <P>
              Любого рода матерные оскорбления, унижения и дискриминация по
              различным признакам - то есть попытка задеть чувства другого
              игрока и вывести на агрессивные действия в отношении других
              участников проекта.
              <br /> Любые матерные оскорбления:
              <br /> Примеры нарушающие данное правило: "Д*бил", "Ху*сос",
              "У*бок", "Ебл*н", "Долб*еб" и т.д.
              <br />
              <br /> Дискриминация по различным признакам:
              <br /> • пола: "женщина посудомойка", "женщина иди на кухню" и
              т.д.
              <br /> • расы и национальности: "хач", "чурка", "негр", "хохол",
              "пиндос" ,"кацап" ,"жид" и т.д.
              <br /> • религии: "резал Аллаха", "Бог под нами", "сжег Коран" и
              т.д.
              <br /> • людей с ограниченными возможностями: "Аутист", "Да*н",
              "ДЦП" и т.д.
              <br />
              <br /> Реклама любых проектов имеющих свои сервера CS.
              <br /> P.S. Не запрещены сайты азартных игр, которые не
              рекламируют и не имеют игровые сервера CS.
              <br />
              <br /> Использование никнеймов, которые содержат названия
              эротического или порнографического содержания, которые являются
              неприемлемыми и нарушают правила этикета и приличия .
              <br /> Примеры нарушающие данное правило: "п*зда", "член",
              "конча", "сквирт", "анал", "елда" и т.д.
              <br /> Примеры НЕ нарушающие данное правило: "сиськи", "сосок",
              "попа", "жопа", "вагина", "машонка" и т.д.
              <br />
              <br /> Использование никнеймов и изображений, которые содержат
              названия запрещенных в РФ политических акций, объединения,
              наркотических веществ или неадекватных движений.
              <br /> Примеры нарушающие данное правило: "игил", "ВСУ", "фашизм",
              "гитлер", "АУЕ", "синий кит", "SchoolShooting", "Нацистская
              символика", "1488", "dr*gs", "мар*хуана", "курю д*рь", "н*свай"и
              т.д.
              <br /> Примеры НЕ нарушающие данное правило: "Za Россию", "Z",
              "V", "бандит", "хулиган", "вор", "снюс", "шишка", "колёса" и т.д.
              <br />
              <br /> Использование никнеймов и изображений, которые могут
              провоцировать или распространять ненависть, насилие или
              экстремизм.
              <br /> Примеры нарушающие данное правило: "суициднись", "kys",
              "сдохни" и т.д.
              <br />
              <br /> Выдача себя за администратора - т.е. использование
              никнеймов и изображений, которые могут вводить в заблуждение
              других игроков, выдавая себя за администраторов или представителей
              игровой платформы. Игроки не должны создавать никнеймы и
              изображения, которые могут внушать другим игрокам, что они
              обладают специальными привилегиями, властью или особыми
              полномочиями на проекте.
              <br /> Примеры нарушающие данное правило: "админ", "moderator",
              "куратор", "owner" и т.д.
              <br />
              <br /> Оскорбление родных или их упоминание.
              <br /> Запрещено использовать любые упоминания родных в любом
              формате, а также их производные формы.
              <br /> Примеры нарушающие данное правило: "мама", "мачеха",
              "папа", "отчим", "брат", "сестра", "бабушка", "дедушка", "mq",
              "rnq"
              <br /> Примеры НЕ нарушающие данное правило: "братишка", "братик",
              "тётя", "дядя", "родной", "семья", "бабуля", "дедуля" и т.д.
              <br />
              <br /> Прямое или косвенное оскорбление любых сервисов проекта.
              <br /> Запрещено использовать любое прямое или косвенное
              оскорбление любых сервисов проекта в любом формате.
              <br /> Примеры нарушающие данное правило: "сервера г*вно", "проект
              ху*ня" и т.д.
              <br />
              <br /> Если одно из определений вашего никнейма будет нарушать
              выше перечисленные правила - Ваш никнейм будет являться
              запрещенным.
            </P>
          </details>
        </Text>

        <Text>
          3.9 В отправляемых вами репортах или названии оружии запрещено:
          <br /> ● Любого рода оскорбления, унижения и дискриминация по
          различным признакам.
          <br /> ● Реклама серверов CS.
          <br /> ● Запрещенные в РФ политические акции, группировки,
          объединения, наркотические вещества и подобные неадекватные движения
          запрещенные в РФ.
          <br /> ➤ Бан на час ➤ неделю
          <br /> ● Любое оскорбление или принижение родных.
          <br /> ● Прямое или косвенное оскорбление любых сервисов проекта.
          <br /> ➤ Бан на неделю ➤ месяц
          <details>
            <Summary>Пояснение</Summary>
            <P>
              Любого рода матерные оскорбления, унижения и дискриминация по
              различным признакам - то есть попытка задеть чувства другого
              игрока и вывести на агрессивные действия в отношении других
              участников проекта.
              <br /> Любого рода оскорбление:
              <br /> Примеры нарушающие данное правило: "Д*бил", "Л*х",
              "Чучело", "Ху*сос", "У*бок", "Ебл*н", "Дурак", "Долб*еб" и т.д.
              <br />
              <br /> Любого рода унижение:
              <br /> Примеры нарушающие данное правило: "Бот", "Глупый",
              "Неудачник", "Сдохни", "Иди на хуй" и тд
              <br />
              <br /> Дискриминация по различным признакам
              <br /> • пола: "женщина посудомойка", "мужлан", "женщина иди на
              кухню" и т.д.
              <br /> • расы и национальности: "хач", "чурка", "негр", "хохол",
              "пиндос" ,"кацап" ,"жид" и т.д.
              <br /> • религии: "резал Алаха", "Бог под нами", "сжег Коран" и
              т.д.
              <br /> • людей с ограниченными возможностями: "Аутист", "Да*н",
              "ДЦП" и т.д.
              <br />
              <br /> Реклама любых проектов имеющих свои сервера CS
              <br /> P.S. Не запрещены сайты азартных игр, которые не
              рекламируют и не имеют игровые сервера CS2
              <br />
              <br /> Использование никнеймов и изображений, которые содержат
              названия запрещенных в РФ политических акций, объединения,
              наркотических веществ или неадекватных движений
              <br /> Примеры нарушающие данное правило: "игил", "ВСУ", "фашизм",
              "гитлер", "АУЕ", "синий кит", "SchoolShooting", "Нацистская
              символика", "1488", "dr*gs", "мар*хуана", "курю д*рь", "н*свай"и
              т.д.
              <br /> Примеры НЕ нарушающие данное правило: "Za Россию", "Z",
              "V", "бандит", "хулиган", "вор", "снюс", "шишка", "колёса" и т.д.
              <br />
              <br /> Использование никнеймов и изображений, которые могут
              провоцировать или распространять ненависть, насилие или экстремизм
              <br /> Примеры нарушающие данное правило: "суициднись", "kys",
              "сдохни" и т.д.
              <br />
              <br /> Прямое или косвенное оскорбление любых сервисов проекта
              <br /> Запрещено использовать любое прямое или косвенное
              оскорбление любых сервисов проекта в любом формате
              <br /> Примеры нарушающие данное правило: "сервера г*вно", "проект
              ху*ня" и т.д.
              <br />
              <br /> Если одно из определений ваших слов, фраз и изображений
              будет нарушать выше перечисление правила - вы понесете
              соответствующее наказание.
            </P>
          </details>
        </Text>
        <Text>
          3.10 На всех игровых и информационных платформах нашего проекта
          запрещено обманывать игроков на игровые ценности. <br /> Мы не несём
          ответственности, если Вы играете на кредиты по договоренности, которые
          не предусмотрены сайтом. <br />
          Важно заметить, что для подачи жалобы на игрока, обманувшего Вас,
          необходимо иметь видеодоказательства. <br /> ➤ Бан на месяц ➤ навсегда
        </Text>
        <Text>
          3.11 Запрещается обход блокировки любым способом.
          <br /> ➤ Бан навсегда
          <details>
            <Summary>Пояснение</Summary>
            <P>
              Под обходом блокировки понимается ситуации, когда игрок, имея
              активное наказание (мут или бан) на любом из своих аккаунтов,
              использует другой аккаунт для доступа к функциям, которые были
              заблокированы из-за наказания на любом из ваших аккаунтов. <br />{" "}
              В случае самопризнания обхода блокировки игроком администратору
              разрешено выдать наказание имея доказательства!
            </P>
          </details>
        </Text>

        <Title>Правила для администрации и для заметки игрокам</Title>
        <Text>
          Основной обязанностью администратора является отслеживание нарушений
          установленных правил на нашем игровом сервере.
        </Text>
        <Text>I. Основные положения:</Text>
        <Text>
          1.1 Администраторам категорически запрещается нарушать правила на всех
          серверах и сервисах проекта.
        </Text>
        <Text>
          1.2 Администратор обязан быть активным на сервере и выполнять свои
          обязанности, без возможности бездействия.
        </Text>
        <Text>
          1.3 Каждый администратор, получивший привилегии, обязан освоить
          систему администрирования проекта и успешно пройти весь курс обучения.
          <details>
            <Summary>Пояснение</Summary>
            <P>
              Каждый администратор проекта Freez-Blood обязан пройти полное
              обучение и итоговое тестирование по администрированию системы.
              <br />
              <br />
              Обучение состоит из специально разработанной программы
              видео-уроков, охватывающей ключевые аспекты администрирования.
              Процесс проходит на нашем Discord-сервере в специальном канале.
              После изучения материалов необходимо сдать тестирование,
              подтверждающее знание системы и профессионализм администратора.
              Тест включает разнообразные вопросы, оценивающие навыки
              администратора.
              <br />
              <br />
              Администратор должен успешно сдать тестирование в течение 13 дней
              после получения роли "стажер". Если тестирование не будет
              завершено за этот период, стажер будет снят с должности.
              <br />
              <br />
              Программа обучения включает три важных части:
              <br />
              1. Освоение необходимого программного обеспечения для выявления
              стороннего ПО. Администраторы знакомятся с каждой программой
              пошагово, что обеспечивает глубокое понимание и уверенное владение
              знаниями.
              <br />
              2. Изучение правил проекта. Администраторы разбирают каждое
              правило для обеспечения порядка и хорошей атмосферы на серверах
              Freez-Blood.
              <br />
              3. Два теста: по правилам проекта и по навыкам нахождения
              стороннего ПО.
              <br />
              <br />
              Успешное прохождение тестирования позволяет стажерам стать
              модераторами, в противном случае потребуется повторное
              тестирование.
              <br />
              <br />
              После завершения обучения администраторы будут уверенно находить
              стороннее ПО и эффективно работать в рамках правил проекта.
            </P>
          </details>
        </Text>
        <Text>
          1.4 В случае подозрений на нарушение правил проекта, администратор
          имеет право вызвать игрока на проверку, которая обязательно проводится
          в официальном дискорде проекта: https://discord.gg/freez-blood. Для
          поиска стороннего ПО используются программы, доступные в дискорде
          нашего проекта. В проверке могут участвовать несколько модераторов.
        </Text>
        <Text>
          1.5 Администратор обязан выдавать муты, баны и вызывать на проверку
          через панель администратора.
        </Text>
        <Text>
          1.6 Всем администраторам проекта строго запрещено разводить ссоры и
          оскорблять в любом формате общения, даже в личных сообщениях.
        </Text>
        <Text>
          1.7 Доступ к администрированию может быть аннулирован по решению
          старшей администрации проекта в случае выявления нарушений правил
          проекта администратором.
        </Text>
        <Text>
          1.8 Администраторам запрещено прикрывать нарушителей. При обнаружении
          таких действий доступ к администрированию будет аннулирован.
        </Text>
        <Text>II. Положения о наказаниях:</Text>
        <Text>
          2.1 Администратор не может наказывать игроков за использование читов,
          скриптов или сторонних программ без проверки, за исключением случаев,
          когда имеется 100% уверенность в использовании RAGE (крутилка), AIM
          или ВХ.
        </Text>
        <Text>
          2.2 Администратор не имеет права предоставлять обычному игроку
          возможность выдавать себя за помощника администратора.
        </Text>
        <Text>
          2.3 Администратор обязан иметь и хранить в течении трех дней
          видеозапись проверок на использование стороннего ПО, нарушений и
          наказаний.
        </Text>
        <Text>
          2.4 Администратор вправе не предоставлять игрокам доказательства их
          нарушений, однако обязан предоставить старшей администрации по их
          просьбе.
        </Text>
        <Text>
          2.5 Администратор имеет право наказывать игроков в случае признания их
          использования запрещенного программного обеспечения во время проверки.
        </Text>
        <Text>
          2.6 Администратору запрещено обманывать вышестоящую администрацию. При
          выявлении таких действий администратора могут снять с поста и
          заблокировать доступ к игровым серверам.
        </Text>
        <Text>
          2.7 Во время проведения проверки на наличие читов в Discord сервере
          могут присутствовать только следующие лица: проверяющий, два стажера,
          два помощника по проверкам и старший состав. Присутствие любых других
          лиц в процессе проверки строго запрещено.
        </Text>
        <Text>
          2.8 Администраторам строго запрещается использовать свои привилегии не
          по назначению, угрожать участникам или нарушать их частную жизнь путем
          кражи личной информации. При обнаружении таких действий ваш доступ к
          серверам будет немедленно заблокирован. Кроме того, подчеркиваем, что
          любое превышение полномочий администраторами также будет
          рассматриваться серьезно, и в случае подтверждения таких нарушений, им
          будет немедленно ограничен доступ к серверам.
        </Text>
        <Text>
          Если Вы хотите получить амнистию, то вы можете написать{" "}
          <Url href="https://freez-blood.com/support">заявку</Url> на разбан или
          размут.
        </Text>
      </Wrapper>
    </Layout>
  );
}
