import { endpoints, url } from "API";
import axios from "axios";

export class BattlPassServise {
  static async getMe() {
    try {
      const response = await axios.get(
        url + endpoints.battlPass.battlPass + endpoints.battlPass.me,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      // console.log(error);
    }
  }

  static async getBattlPass() {
    try {
      const response = await axios.get(url + endpoints.battlPass.battlPass, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      // console.log(error);
    }
  }

  static async getPrize(id: Number, Prem: number) {
    try {
      const response = await axios.get(
        url +
          endpoints.battlPass.battlPass +
          endpoints.battlPass.prize +
          `?prizeId=${id}&isPremium=${Prem}`,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      return error.response.data.message;
    }
  }

  static async BuyXp(xp: number) {
    try {
      const response = await axios.get(
        url +
          endpoints.battlPass.battlPass +
          endpoints.battlPass.buy +
          `?xp=${xp}`,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return error.response.data.message;
    }
  }
  static async GetInfoSoc() {
    try {
      const response = await axios.get(url + "/profile/socials/verify", {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      return error.response.data.message;
    }
  }
}
