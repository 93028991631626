import ModalWrapper from "components/Templates/ModalWrapper";
import Input from "components/UI/Input";
import { Container } from "./styled";
import Button from "components/UI/Button";
import { useState } from "react";
import { ClansServise } from "API/ClansServise";

interface Props {
  isActive: boolean;
  setActive: React.SetStateAction<any>;
}
export default function CreateClansModal({ isActive, setActive }: Props) {
  const [name, setName] = useState<string>("");
  const [urlAvatar, setUrlAvatar] = useState<string>("");

  return (
    <ModalWrapper
      isActive={isActive}
      setActive={setActive}
      display={"block"}
      headContent={"Создать клан"}
      params={{ width: "60rem", height: "max-content" }}
    >
      <Container>
        <Input
          type="url"
          label="Ссылка на аватарку"
          placeholder="Введите ссылку"
          params={{ width: "100%" }}
          onChange={(e) => setUrlAvatar(e.target.value)}
        />
        <Input
          type="url"
          label="Название клана"
          placeholder="Введите название клана"
          params={{ width: "100%" }}
          onChange={(e) => setName(e.target.value)}
        />
        <Button
          height="5rem"
          variant="default"
          color="blue"
          onClick={() => ClansServise.CreateClan(name, urlAvatar)}
        >
          Создать клан
        </Button>
      </Container>
    </ModalWrapper>
  );
}
