import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const StyledAside = styled.div`
  width: ${(props) => props.theme.consts.navBarWidth};
  height: 100%;
  background: ${(props) => props.theme.colors.menu_bg};
  position: fixed;
  top: 0;
  left: 0;
  /* display: none; */
  @media (max-width: 1250px) {
    display: none;
  }
`;

export const Wrapper = styled.div``;

export const Item = styled(NavLink)`
  cursor: pointer;
  width: 100%;
  height: fit-content;
  text-align: center;
  padding: 1.1rem 0;
  transition: all 0.15s;
  flex-direction: column;
  ${(props) => props.theme.fonts.GothamMenuMedium14}
  ${(props) => props.theme.mixins.dfcenter}
	color: ${(props) => props.theme.colors.white.white70};
  gap: 0.7rem;
  position: relative;

  &.active {
    color: ${(props) => props.theme.colors.white.white100};
    background: ${(props) => props.theme.stroke.stroke5};
    &::before {
      content: " ";
      top: 0;
      left: 0;
      border-radius: 0 2rem 2rem 0;
      position: absolute;
      width: 0.2rem;
      height: 100%;
      background: ${(props) => props.theme.colors.blue.hover};
    }
    svg > * {
      transition: all 0.15s;
      fill-opacity: 1 !important;
    }
  }
`;
export const ItemOrange = styled(NavLink)`
  cursor: pointer;
  width: 100%;
  height: fit-content;
  text-align: center;
  padding: 1.1rem 0;
  transition: all 0.15s;
  flex-direction: column;
  ${(props) => props.theme.fonts.GothamMenuMedium14}
  ${(props) => props.theme.mixins.dfcenter}
	color: #FAB700;
  gap: 0.7rem;
  position: relative;

  &.active {
    color: #fab700;
    background: ${(props) => props.theme.stroke.stroke5};
    &::before {
      content: " ";
      top: 0;
      left: 0;
      border-radius: 0 2rem 2rem 0;
      position: absolute;
      width: 0.2rem;
      height: 100%;
      background: ${(props) => props.theme.colors.blue.hover};
    }
    svg > * {
      transition: all 0.15s;
      fill-opacity: 1 !important;
    }
  }
`;

export const Logo = styled.div`
  ${(props) => props.theme.mixins.dfcenter};
  padding: 3rem 3.5rem;
`;
