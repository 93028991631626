import { IButton } from "interfaces/Button";
import styled, { css } from "styled-components";

export const Button = styled.button<IButton>`
  padding: ${(props) =>
    props.params?.padding ? props.params.padding : "1.5rem 2rem"};
  ${(props) => props.theme.fonts.GothamRegular16};
  transition: 0.3s all;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  ${(props) => props.fullWidth && "width: 300%"};
  width: ${(props) => props.params?.width && props.params.width};
  height: ${(props) => props?.height && props.height};
  &:disabled {
    background: ${(props) => props.theme.colors.btn.off};
    &:hover {
      background: ${(props) => props.theme.colors.btn.off_hover};
    }
    &:active {
      background: ${(props) => props.theme.colors.btn.off_active};
    }
  }
  ${(props) =>
    props.variant === "default" &&
    css`
      ${(props) => props.theme.fonts.GothamBold16};
      display: flex;
      align-items: center;
      gap: 0.8rem;
      border-radius: 2rem;
    `}

  ${(props) =>
    props.variant === "picker" &&
    css`
      ${(props) => props.theme.fonts.GothamRegular16};
      border-radius: 1.5rem;
      background: ${(props) => props.theme.colors.white.white10};
      &:hover {
        ${(props) => props.theme.fonts.GothamMedium16};
        background: ${(props) => props.theme.colors.white.white20};
      }
      &.active {
        background: ${(props) => props.theme.colors.white.white100};
        ${(props) => props.theme.fonts.GothamBold16};
        color: ${(props) => props.theme.colors.blue.accent};
      }
    `}
    
    ${(props) =>
    props.variant === "Sort" &&
    css`
      ${(props) => props.theme.fonts.GothamRegular16};
      border-radius: 1.5rem;
      background: none;
      &:hover {
        ${(props) => props.theme.fonts.GothamMedium16};
      }
      &.active {
        background: ${(props) => props.theme.colors.white.white100};
        ${(props) => props.theme.fonts.GothamBold16};
        color: ${(props) => props.theme.colors.blue.accent};
      }
    `}

	${(props) =>
    props.color === "blue" &&
    css`
      color: ${(props) => props.theme.colors.white.white100};
      background: ${(props) => props.theme.colors.blue.accent};
      &:hover {
        background: ${(props) => props.theme.colors.btn.hover};
      }
      &:active {
        background: ${(props) => props.theme.colors.btn.pressed};
      }
    `}
    

    ${(props) =>
    props.color === "grayy" &&
    css`
      color: ${(props) => props.theme.colors.white.white100};
      background: ${(props) => props.theme.colors.white.white20};
      /* &:hover {
        background: ${(props) => props.theme.colors.btn.hover};
      } */
      &:active {
        background: ${(props) => props.theme.colors.btn.pressed};
      }
    `}
     ${(props) =>
    props.color === "shadow" &&
    css`
      display: flex;
      gap: 2rem;
      color: ${(props) => props.theme.colors.white.white100};
      background: ${(props) => props.theme.gradients.shadow};
      /* &:hover {
        background: ${(props) => props.theme.colors.btn.hover};
      } */
      &:active {
        background: ${(props) => props.theme.colors.white.white20};
      }
    `}
    ${(props) =>
    props.color === "orangeVIP" &&
    css`
      gap: 2rem;
      ${(props) => props.theme.fonts.GothamBold20}
      color: ${(props) => props.theme.colors.black.black100};
      background: #ffba35;
      /* &:hover {
        background: ${(props) => props.theme.colors.btn.hover};
      } */
      &:active {
        background: ${(props) => props.theme.colors.btn.pressed};
      }
    `}

    ${(props) =>
    props.color === "blueVIP" &&
    css`
      gap: 2rem;
      ${(props) => props.theme.fonts.GothamBold20}
      color: ${(props) => props.theme.colors.black.black100};
      background: #1e91e4;
      /* &:hover {
        background: ${(props) => props.theme.colors.btn.hover};
      } */
      &:active {
        background: ${(props) => props.theme.colors.btn.pressed};
      }
    `}
	
	${(props) =>
    props.color === "red" &&
    css`
      color: ${(props) => props.theme.colors.white.white100};
      background: ${(props) => props.theme.colors.red.accent};
      &:hover {
        background: ${(props) => props.theme.colors.red.hover};
      }
      &:active {
        background: ${(props) => props.theme.colors.red.active};
      }
    `}
	${(props) =>
    props.color === "green" &&
    css`
      color: ${(props) => props.theme.colors.white.white100};
      background: ${(props) => props.theme.colors.green.accent};
      &:hover {
        background: ${(props) => props.theme.colors.green.hover};
      }
      &:active {
        background: ${(props) => props.theme.colors.green.pressed};
      }
    `}
`;
