import Copy from "./Copy";
import Play from "./Play";
import Players from "./Players";
import LayoutFirst from "./LayoutFirst";
import LayoutSecond from "./LayoutSecond";
import Shiled from "./Shiled";
import Arrow from "./Arrow";
import Close from "./Close";
import Plus from "./Plus";
import DiscountStar from "./DiscountStar";
import Emoji from "./Emoji";
import Reload from "./Reload";
import SortArrPrice from "./SortRarPrice";
import Inventory from "./Inventory";
import SkinChandger from "./SkinChandger";
import Exit from "./Exit";
import ExclamationMark from "./ExclamationMark";
import Yuap from "./Yuap";
import WeaponConstract from "./WeaponConstract";
import Rub from "./Rub";
import Credit from "./Credit";
import Winner from "./Winner";
import Billet from "./Billet";
import Rus from "./Rus";
import World from "./World";
import PlusWhite from "./PlusWhite";
import Chat from "./Chat";
import BlockPlayer from "./BlockPlayer";
import Okey from "./Okey";
import Crown from "./Crown";
import MedalPoints from "./MedalPoints";
import TaskBP from "./TaskBP";
import Swords from "./Swords";
import RightBranch from "./RightBranch";
import LeftBranch from "./LeftBranch";
import XpClans from "./XpClans";
import UsersClans from "./UsersClans";
// import Chat from "./Chat"

export const TinyIcons = {
  UsersClans,
  XpClans,
  RightBranch,
  LeftBranch,
  Swords,
  TaskBP,
  MedalPoints,
  // Chat,
  Crown,
  Okey,
  BlockPlayer,
  Chat,
  PlusWhite,
  World,
  Rus,
  Billet,
  Rub,
  Credit,
  Copy,
  Inventory,
  SkinChandger,
  Exit,
  Play,
  Players,
  LayoutFirst,
  LayoutSecond,
  Shiled,
  Arrow,
  Close,
  Plus,
  DiscountStar,
  Emoji,
  Reload,
  SortArrPrice,
  ExclamationMark,
  Yuap,
  WeaponConstract,
  Winner,
};
