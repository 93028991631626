import { ClanRolesEnum, ClansArray } from "interfaces/Clans";
import { makeAutoObservable } from "mobx";

class ClansState {
  public Clans: ClansArray | undefined = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  public SetClans(clan: ClansArray) {
    this.Clans = clan;
  }
}

export default new ClansState();
