import { ProfileContext } from "context/profile";
import { memo, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useActions } from "hooks/useActions";
import { isYou } from "utils/IsYou";
import {
  Stats,
  Inventory,
  Friends,
  Messages,
  Notifications,
  SkinChanger,
  Settings,
} from "./Tabs";
import Applications from "./Tabs/Applications";
import Privileges from "./Tabs/Privileges/Privileges";

function Profile() {
  const { tab: currentTab, steamid64 } = useParams();
  const { setInventory } = useActions();

  const [tab, setTab] = useState(0);

  useMemo(() => {
    setInventory([]);
  }, [steamid64]);

  return (
    <ProfileContext.Provider value={{ tab, setTab }}>
      {currentTab === undefined && <Stats />}
      {currentTab === "inventory" && <Inventory />}
      {currentTab === "skinchanger" && <SkinChanger />}
      {currentTab === "privileges" && <Privileges />}
      {currentTab === "friends" && <Friends />}
      {currentTab === "settings" && <Settings />}
      {currentTab === "settings" && isYou(steamid64) && <></>}
      {currentTab === "messages" && isYou(steamid64) && <Messages />}
      {currentTab === "notifications" && isYou(steamid64) && <Notifications />}
      {currentTab === "applications" && isYou(steamid64) && <Applications />}
    </ProfileContext.Provider>
  );
}

export default memo(Profile);
